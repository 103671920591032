import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import logo from '../images/logo.png';
import favicon from '../images/favicon.png';
import og_image from '../images/distribuidora.jpg';

import Header from '../components/header';
import Footer from '../components/footer';

import './all.css';

const Layout = ({ children }) => (
	<React.Fragment>
		<Helmet>
			<html lang="pt-br" />
			<meta charSet="utf-8" />
			<title>Goods Br</title>
			<meta
				name="description"
				content="Somos uma marca de artigos de decoração que nasceu da união de sonhos, sofisticação e paixão por fazer diferente."
			/>

			<link rel="icon" type="image/png" href={favicon} />

			<meta name="theme-color" content="#000" />

			<meta property="og:type" content="business.business" />
			<meta property="og:title" content="Goods Br" />
			<meta property="og:image" content={og_image} />

			<link href="https://fonts.googleapis.com/css?family=Roboto|Roboto+Slab" rel="stylesheet" />
		</Helmet>

		<Header />

		{children}

		<div className="about-goodsbr">
			<div className="container">
				<div className="about-content">
					<img src={logo} width="214" alt="" className="about-logo" />
					<p>
						Somos uma marca de artigos de decoração que nasceu da união de sonhos, sofisticação e paixão por
						fazer diferente.
					</p>
					<p>
						As peças incorporam um espírito jovem, moderno e irreverente do estilo clássico e atemporal,
						transcendendo modismos e tendências. Com foco na exclusividade, valorizamos detalhes e design
						diferenciado para atender lojas de todo o Brasil que procuram estilo e bom gosto.
					</p>
					<p>
						Estamos em Gramado/RS há mais de dez anos e, em 2018, abrimos o maior showroom de decoração da
						Serra Gaúcha para colocar o universo Goods Br à sua disposição. Visite-nos e se encante!
					</p>
					<a
						href="https://www.goodsbr.com.br/catalogo"
						target="_blank"
						rel="noopener noreferrer"
						className="button w-button"
					>
						Compre os produtos da Goods Br
					</a>
					<Link to="/sobre/" className="button w-button">
						Conheça a história da Goods Br
					</Link>
				</div>
			</div>
		</div>
		<Footer />
	</React.Fragment>
);

export default Layout;
