import React from 'react';
import { Link } from 'gatsby';

import logo from '../images/logo.png';
import whats from '../images/whatsapp.svg';

const Header = class extends React.Component {
	componentDidMount() {
		// Get all "navbar-burger" elements
		const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
		// Check if there are any navbar burgers
		if ($navbarBurgers.length > 0) {
			// Add a click event on each of them
			$navbarBurgers.forEach(el => {
				el.addEventListener('click', () => {
					// Get the target from the "data-target" attribute
					const target = el.dataset.target;
					const $target = document.getElementById(target);

					// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
					el.classList.toggle('is-active');
					$target.classList.toggle('is-active');
				});
			});
		}
	}
	render() {
		return (
			<div data-collapse="medium" data-animation="default" data-duration="400" className="navbar w-nav">
				<div className="container">
					<Link to="/" className="w-nav-brand w--current">
						<img src={logo} width="175" alt="" className="nav-logo" />
					</Link>
					<nav id="navMenu" role="navigation" className="nav-menu w-nav-menu">
						<Link to="/sobre/" className="nav-link w-nav-link">
							Sobre a Empresa
						</Link>
						<a
							href="https://blog.goodsbr.com.br/"
							target="_blank"
							rel="noopener noreferrer"
							className="nav-link w-nav-link"
						>
							Blog
						</a>
						<Link to="/contato/" className="nav-link w-nav-link">
							Contato
						</Link>
						{/* <a
              href="https://wa.me/5554996991296"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link w-nav-link buy-cta-mobile"
              style={{ color: 'white' }}
            >
              <img
                src={whats}
                alt="Whatsapp"
                style={{
                  marginRight: '10px',
                  height: '18px',
                }}
              />
              Enviar Whatsapp
            </a> */}
					</nav>

					{/* <div className="buy-cta">
            <a
              href="https://wa.me/5554996991296"
              target="_blank"
              rel="noopener noreferrer"
              className="button w-button whats-button"
            >
              <img
                src={whats}
                alt="Whatsapp"
                style={{
                  marginRight: '10px',
                  height: '18px',
                }}
              />
              Enviar Whatsapp
            </a>
          </div> */}

					<div className="navbar-burger hamburguer-button w-nav-button" data-target="navMenu">
						<div className="hamburguer w-icon-nav-menu" />
					</div>
				</div>
			</div>
		);
	}
};

export default Header;
