import React from 'react';

const Footer = () => (
	<footer className="footer">
		<div className="container">
			<div className="footer-content w-clearfix">
				<div className="footer-text">
					<strong>Endereço</strong>: Av. Primeiro de Maio, 3400, Bairro Várzea Grande - Gramado, RS CEP:
					95.670-000
				</div>
				<div className="footer-phone">
					<div className="footer-text phone">
						<strong>Telefone:</strong>
					</div>
					<a href="tel:(54)3295-5300" className="footer-text link">
						(54) 3295-5300
					</a>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
